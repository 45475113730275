<template>
  <v-app-bar
    :height="$vuetify.display.smAndUp ? 68 : 56"
    absolute
    flat
    class="app-bar d-flex align-center px-3 px-sm-4"
  >
    <!--  logo-icon+title  -->
    <nuxt-link
      class="logo-link"
      :class="$vuetify.display.name"
      :to="useRuntimeConfig().public.app.ssoUrl"
    >
      <logo
        width="36"
        height="36"
        class="mr-1"
      />
      <text-logo
        v-if="$vuetify.display.smAndUp"
        width="100"
        height="14"
      />
    </nuxt-link>

    <!--  navigation tabs  -->
    <v-tabs color="beton80">
      <v-tab
        v-for="(el, key) in navigationTabs"
        :key="key"
        class="text-body-1"
        :exact="el.exact"
        :value="el.value"
        :to="el.value"
        :text="el.text"
        rounded="0"
      />
    </v-tabs>

    <v-spacer class="flex-grow-1" />

    <!--  profile button and menu dropdown  -->
    <x-drop-down-button
      :items="profileMenuItems"
      :visible-activator="authService.isLoggedIn && !!userStore.currentUser"
      :dropdown-icon="!$vuetify.display.xs"
      :button-min-width="!$vuetify.display.xs ? '190' : undefined"
      button-max-width="225"
      @select="selectProfileMenuItem"
    >
      <v-avatar
        class="avatar"
        :class="`avatar__${$vuetify.display.name}`"
        :size="32"
      >
        {{ avatarLetters }}
      </v-avatar>
      <div
        v-if="$vuetify.display.smAndUp"
        class="d-flex flex-column text-left"
        :style="{ maxWidth: `${235 - 70}px` }"
      >
        <div
          v-if="userStore.currentUser"
          class="text-body-1 text-beton text-overflow"
        >
          {{ `${userStore.currentUser.firstName} ${userStore.currentUser.lastName}` }}
        </div>
        <div
          v-if="userStore.organizationName"
          class="text-body-2 text-beton48 text-overflow"
        >
          {{ userStore.organizationName }}
        </div>
      </div>
    </x-drop-down-button>

    <x-dialog
      v-model="isSupportDialogVisible"
      :max-width="$vuetify.display.xs ? '95%' : 360"
    >
      <div
        class="text-beton text-center mx-auto mb-8"
        style="max-width: 290px"
      >
        <h2 class="mb-4">Контакты службы поддержки</h2>
        <div class="text-body-1">
          <p class="mb-2">
            Наши специалисты всегда готовы ответить на ваши вопросы и помочь решить любые
            технические проблемы работы нашего сервиса.
          </p>
          <p class="mb-2">Свяжитесь с нами по указанным контактам</p>
          <p class="mb-2">
            Тел.:
            <a
              href="tel:+79652822626"
              class="text-h4 text-blue"
              >+7 965 282-26-26</a
            >
          </p>
          <p class="mb-2">
            Почта:
            <a
              href="mailto:info@fishplan.ru"
              class="text-h4 text-blue"
              >info@fishplan.ru</a
            >
          </p>
        </div>
      </div>
    </x-dialog>
  </v-app-bar>
</template>

<script setup lang="ts">
import { AuthService } from '~/app-modules/auth/services/auth.service';
import { UserStore } from '~/app-modules/user/stores/user.store';

defineOptions({
  name: 'MainHeader',
});

const authService = useIOC(AuthService);
const userStore = useIOC(UserStore);

const avatarLetters = computed(
  () =>
    userStore.currentUser &&
    `${userStore.currentUser.firstName[0]}${userStore.currentUser.lastName[0]}`
);

const navigationTabs = computed(() =>
  authService.isLoggedIn
    ? [
        {
          value: '/farm',
          exact: false,
          text: 'Хозяйство',
        },
        {
          value: '/warehouses',
          exact: false,
          text: 'Склады',
        },
      ]
    : []
);

const isSupportDialogVisible = ref(false);

type TItem = { label: string; icon?: string; value?: string; callback?: () => {} };

const profileMenuItems: Array<TItem> = [
  { label: 'Личный кабинет', icon: '$edit-pen', value: 'lk' },
  { label: 'Служба поддержки', icon: '$headphones', value: 'support' },
  { label: 'Выход', icon: '$logout', value: 'logout' },
];

async function selectProfileMenuItem(item: TItem) {
  switch (item.value) {
    case 'lk': {
      const ssoUrl = await authService.getSsoSignInUrl('');
      window.location.href = ssoUrl.replace(/\/auth.+$/, '').concat('/lk/');
      break;
    }
    case 'support': {
      isSupportDialogVisible.value = true;
      break;
    }
    case 'logout': {
      authService.logout();
      window.location.reload();
      break;
    }
    default:
  }
}
</script>

<style lang="scss" scoped>
.app-bar {
  border-bottom: 1px solid rgb(var(--v-theme-beton12));
}

.logo-link {
  display: flex;
  align-items: center;

  &.xs {
    margin-right: 8px;
    margin-left: 8px;
  }

  &.sm {
    width: 224px;
  }

  &.md {
    width: 280px;
  }

  &.lg,
  &.xl {
    width: 320px;
  }
}

.avatar {
  margin-right: 6px;
  color: white;
  @include get-text-styles(h4);
  font-stretch: condensed;
  background: linear-gradient(18deg, #194a7f 15.64%, #246da4 63.13%, #2e8bc4 91.06%);
}

.dropdown-button {
  display: block;
  padding-left: 6px;
  padding-right: 6px;
}

:deep(.dropdown-button__xs) {
  padding-right: unset;
}

.drop-down-icon {
  margin-top: 2px;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);

  &.active {
    transform: rotate(180deg);
  }
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
