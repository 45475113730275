<template>
  <v-app>
    <v-main class="d-flex">
      <!--   top header   -->
      <main-header />

      <div
        class="page-content"
        :class="`page-content__${$vuetify.display.name}`"
      >
        <slot />
      </div>
    </v-main>
  </v-app>
</template>

<script setup></script>

<style lang="scss" scoped>
.page-content {
  flex: 1 1 auto;
}
</style>
